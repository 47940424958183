(function ($) {

    $(document).ready(function () {

    });


}(jQuery));

function set_form_height(height) {
    $('.gravity_form iframe').attr('height', height);
}